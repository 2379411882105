@use '@edwin-edu/ui/vars' as *;

@layer edwin {
  .hr {
    margin-top: $space-4x;
    margin-bottom: $space-4x;
    border-bottom: 1px solid $color-neutral-200;
    flex: 1 1 0;
  }
}
