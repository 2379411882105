@use '~@edwin-edu/ui/vars' as *;

.modalContainer {
  position: relative;
  padding: 5px;
}

.modal {
  width: 90%;
  min-width: 200px;
  min-height: auto;
  padding: 20px;
  margin: auto;
  flex: 0 1 auto;
  overflow-y: auto;
  transform: scale(1) translateX(0);
  @include scrollbar();
}

.closeButton {
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
  color: $color-base-300;
  opacity: 0.6;
  font-size: 14px;
}

.closeModalButton {
  position: absolute;
  color: $color-base-300;
  z-index: 10;
  right: 10px;
  top: 10px;
  display: block;
  padding: 5px;
}

.sectionTitle {
  margin-bottom: 10px;
  color: $color-base-300;
  font-weight: 300;
  font-size: 28px;
  letter-spacing: -1.05px;
}

.addItemsDescription {
  margin-top: 25px;
}

.addLearningObjectsDescription {
  display: inline-flex;
  vertical-align: middle;
}

.addCollectionIcon {
  margin-left: 4px;
  margin-right: 2px;
  width: 25px;
  height: auto;
}

.addItemsButton {
  margin: 10px auto;
  font-size: 14px;
}

.fileFormButtonContainer {
  display: flex;
  flex-direction: row;
  gap: 8;
  justify-content: space-between;
}

.addItemsModalButtonsContainer {
  justify-content: space-between;
}

.italicText {
  font-style: italic;
  text-align: center;
  max-width: 200px;
  font-size: 12px;
  line-height: 1.25;
}

.inputContainer {
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.inputLabel {
  flex: 1 0 auto;
  text-align: right;
  margin-right: 10px;
  font-style: italic;
  color: $color-neutral-400;
  font-weight: 400;
  font-size: 16px;
}

.input {
  width: 100%;
  margin: 1.5rem 0px 2rem;
  padding-bottom: 0.25rem;
  border-color: $color-base-300;
  border-width: 0px 0px 1px; // Only set the bottom border
  color: $color-base-300;
  font-size: 1rem;
  outline: none;
  border-bottom-style: solid;
  border-bottom-color: $color-base-300;

  &:focus {
    border-width: 0px 0px 1px;
    border-bottom-color: $color-base-300;
    outline: none;
  }
}

.duplicateIcon {
  cursor: pointer;
  margin-top: 5px;
  margin-right: 9px;
}

.inputLong {
  border: 1px solid rgb(188, 188, 188);
  color: $color-base-300;
  width: 520px;
  height: 26px;
  padding-left: 5px;
  font-size: 16px;
  font-style: italic;
  letter-spacing: -0.6px;
}

.spinner {
  width: 20px;  // Adjust the width
  height: 20px; // Adjust the height
}

.cancelButton {
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.cancelButtonSpinner {
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 78px;
}

.saveButton {
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.saveButtonSpinner {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; // Ensures buttons and spinner align correctly
  gap: 8px; // Adjust the gap as needed
}

.textarea {
  border: 1px solid rgb(188, 188, 188);
  color: $color-base-300;
  width: 520px;
  height: 72px;
  resize: none;
  font-size: 16px;
  font-style: italic;
  font-family: "Source Sans Pro", Arial, sans-serif;
}

.teacherOnlyContainer {
  position: absolute;
  left: 27px;
}

.teacherOnly {
  font-size: 12px;
  color: $color-neutral-400;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

.teacherOnlyOption {
  margin-top: -10px;
}

.requiredField {
  position: absolute;
  left: 29px;
  bottom: 5px;
  color: $color-neutral-500;
  font-size: 10px;
  letter-spacing: -0.25px;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60px;
  align-items: flex-end;
  margin: 20px 0px 10px;
}

.buttonsContainerFile {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60px;
  align-items: flex-end;
  margin: 15px 0px 10px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;
}

.fileUploadIcon {
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fileFormContainer {
  min-width: 520px;
  height: 100%;
  border-color: rgb(234, 234, 234);
  background-color: rgb(250, 250, 250);
  border-style: dotted;
  border: 1 dotted rgb(234, 234, 234);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fileInput {
  display: none;
}

.fileDescription1 {
  margin-bottom: -4px;
  color: $color-base-300;
  font-size: 16px;

  >span {
    font-weight: bold;
    text-decoration: underline;
  }
}

.fileDescription2 {
  color: #888888;
  font-size: 10px;
  text-align: center;
  font-weight: bold;
  margin-top: -1px;
  margin-bottom: -5px;
}

.fileDescription3 {
  color: #888888;
  font-size: 10px;
  text-align: center;
  font-weight: bold;
  margin-top: -4px;
}

.dropZoneUploadContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.dropZoneUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
  border-width: 2.3px;
  border-radius: 4px;
  color: #017acc;
  transition: border .24s ease-in-out;
  margin: 0 0 5px 0;
  cursor: pointer;
}

.dropContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
  border-width: 2.3px;
  border-radius: 4px;
  color: $color-base-300;
  transition: border 0.24s ease-in-out;
  margin: 0 0 5px 0;
  cursor: pointer;
}

.browseButton {
  margin: 6px auto 0px 0px;
}

.messageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.instructions {
  display: inline;
  text-align: center;
  color: $color-base-300;
  font-weight: 400;
  margin-top: 10px;

  span {
    font-weight: 700;
    font-size: 16px;
    text-decoration: underline;
  }
}

.h6 {
  font-size: 10px;
  color: #888;
  text-align: center;
}

.errorContainer {
  display: flex;
  align-items: center;
  user-select: none;
}

.error {
  color: $color-info-destructive;
  margin-left: 8px;
  font-size: 11px;
}

.selectedFile {
  display: flex;
  align-items: center;
}

.h4 {
  margin-left: 0;
  color: $color-base-300;
  font-size: 16px;
  font-weight: bold;
  display: flex;
}

.thumb {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
}

.thumbsContainer {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
}

.thumbInner {
  display: flex;
  min-width: 0;
  overflow: hidden;
}

.infoContainer {
  display: flex;
  align-content: center;
  overflow: hidden;
}

.thumbnailContainer {
  flex: 1 1 auto;
  background-repeat: no-repeat;
  background-size: contain;
  left: 20%;
  top: 20%;
  background-position: center;
}

.fileUploadIcon {
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.previewContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

.previewImage {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
}

.previewPlaceholder {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  background-color: #fff;
  font-size: 12px;
  text-align: center;
}

.infoContainer {
  display: flex;
  flex-direction: column;
}

.content {
  padding: 0 10px 20px 15px;
  position: relative;
}

.top {
  display: flex;
  margin-top: -20px;
  margin-bottom: 60px;
}

.mainColumn {
  margin-top: 37.5px;
  margin-left: $space-5x;
}

.description {
  margin-top: 5px;
  font-size: 20px;
  font-weight: 400;
  color: $color-neutral-500;
  letter-spacing: -0.4px;
  word-break: break-all;
}

.bottom {
  margin: 20px -20px 0 0;
  padding-right: 20px;
}

.tags {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.4px;
  color: $color-neutral-500;

  span {
    font-weight: 400;
    color: $color-neutral-500;
  }
}

.teacherOnly {
  margin-top: 1.5rem;
}

.title {
  margin-top: 24px;
  color: $color-base-300;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: -0.6px;
}

.curriculumLabel {
  font-weight: 700;
}

.curriculumContent {
  font-weight: 400;
  max-height: 300px;
  margin-top: $space-1x;
}

.subjectsList {
  list-style-type: none;
  margin-left: $space-6x;
}

.subject {
  font-weight: bold;
  font-style: italic;
  margin-bottom: 10px;
}

.strandsList {
  list-style-type: none;
  font-weight: normal;
  font-style: normal;
  margin-left: $space-6x;
}

.strand {
  margin-bottom: 10px;
  font-weight: 400;
  font-style: normal;

  &::before {
    content: '-';
    margin-right: 5px;
  }
}

.overallExpList {
  list-style-type: disc;
  margin-top: 10px;
  text-indent: 0;
  margin-left: $space-10x;
}

.overallExp {
  margin-bottom: 10px;
}

.specificExpList {
  list-style-type: disc;
  margin-top: 10px;
  margin-left: $space-6x;
}

.specificEx {
  margin-bottom: 10px;
}