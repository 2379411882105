@use '~@edwin-edu/ui/vars' as *;

.container {
  flex: 1;
  height: 0;
}

.infoContainer {
  flex: 1;
  height: 0;
}

.infoSection {
  border-radius: $radius-1x;
  box-shadow: $shadow-neutral-0;
  overflow-y: auto;
  @include scrollbar();
}

.information {
  flex: 1;
  background: $color-neutral-0;

  textarea {
    font-size: 14px;
  }
}

.subjectList {
  flex: 0 0 350px;
  background: var(
                  --card-lightgradient,
                  linear-gradient(138deg, #dcf2ff 6.49%, #eaf8ff 99.69%)
  );
}

.warningModal {
  width: 300px;

  svg {
    flex: 0 0 20px;
    margin-top: $space-1x;

    g {
      fill: $color-base-300;
    }
  }
}

.datePickers {
  label {
    font-size: 14px;
    font-weight: 600;
  }
}

.termDates {
  g {
    fill: $color-neutral-500;
  }
}

.rickTextEditor {
  font-size: 14px;
  color: $color-neutral-400;
}

.emptyField {
  button {
    font-size: 14px;
    font-style: italic;
    text-decoration: underline;
  }
}

.header {
  flex-wrap: wrap;
}

.chevron {
  transform: rotate(90deg);

  path {
    stroke: $color-neutral-400;
  }
}

.activeChevron {
  transform: rotate(-90deg);

  path {
    stroke: $color-base-300;
  }
}
