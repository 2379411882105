@use '../../vars' as *;

@layer edwin {
  .base {
    @include h2();
  }
  .mega {
    @include mega();
  }
  .jumbo {
    @include jumbo();
  }
  .h1 {
    @include h1();
  }
  .h2 {
    @include h2();
  }
  .h3 {
    @include h3();
  }
  .h4 {
    @include h4();
  }
  .h5 {
    @include h5();
  }

  .primary {
    color: $color-base-300;
  }
  .success {
    color: $color-info-success;
  }
  .warning {
    color: $color-info-warning;
  }
  .error {
    color: $color-info-destructive;
  }

  .noMargin {
    margin: 0;
  }

  .visuallyHidden {
    @include visuallyHidden();
  }

  @include textHelpers();
}