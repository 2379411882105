.note {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 8px 9px;
  width: 144px;
  height: 98px;
  background: #ffd159;
  border: 2px solid #ffe7ab;
  border-radius: 0 8px 8px 8px;
  box-shadow: 0 8px 12px rgba(74, 74, 74, 0.25);
  user-drag: none;
}

.date {
  font-weight: 600;
  font-size: 9px;
  line-height: 13px;
  letter-spacing: -0.4px;
  margin: 4px 0;
}

.noteTitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.45px;
  height: 58px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  width: 99%;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
