@use '~@edwin-edu/ui/vars' as *;

.tutorial {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(rgb(42, 42, 42), 0.9);
  z-index: 20;
}

@mixin tutorialButton() {
  background: none;
  color: #fff;
  border: 1px solid #fff;
  pointer-events: none;

  path {
    stroke: $white;
  }
}

.tutorialButton {
  @include tutorialButton;
}

.teacherInvite {
  position: absolute;
  right: 395px;
}

.tutorialDropdown {
  button {
    @include tutorialButton;
  }
}

.keywordSearch {
  pointer-events: none;

  input {
    width: 200px;
    background: transparent;
    border-color: $white;
    font-size: 20px;
    &::placeholder {
      color: $white;
      @include body-medium(false);
    }
  }

  button {
    background: none;
    border: 1px solid #fff;
    color: #fff;
    path {
      stroke: #fff !important;
      fill: #fff !important;
    }
  }
}

.tutorialBottomText {
  margin: auto 10px auto 0;
  text-align: center;
  width: 140px;
}

.dropdownExample {
  width: 276px;
  background: $white;
  border-radius: $radius-2x;
  padding: $space-2x 0;
  li {
    margin: 0 $space-4x;
    padding: $space-2x 0;
  }
  li:last-child {
    border-top: 1px solid $color-neutral-300;
  }
}
