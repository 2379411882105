@use '@edwin-edu/ui/vars' as *;

.confirmContainer {
  width: 342px;
  background: $white
}

.warningMessage {
  color: $color-neutral-500;
  letter-spacing: -0.4px;
  line-height: 1.2;
  text-align: center;
}

.teacherOnlyMessage {
  letter-spacing: -0.4px;
  line-height: 1.2;
  width: 302px;
}

.styledRow {
  color: $color-base-300;
  letter-spacing: -0.4px;
  line-height: 1.2;
  font-weight: 600;
}

.iconRow {
  margin-bottom: 0;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1.5px solid $color-base-300;
  margin: -3px 10px auto 0;
}

.teacherOnlyIcon {
  width: 23px;
}

.googleEmbeddedButton {
  position: absolute;
  width: 100%;
  height: 34px;
  border-radius: 30px;
  opacity: 0.01;
  left: -2px;
  top: -2px;
  overflow: hidden;
  z-index: 10;
}

.button {
  text-transform: lowercase;
}

.cancel {
  border: 0;
}
