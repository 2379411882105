@use '../../vars' as *;

.container {
  display: flex;
  align-items: center;
  gap: $space-1x;
}

.input {
  width: 100%;
  padding: $space-1x;
  border: none;
  border-bottom: 1px solid $color-neutral-500;
  background: $color-neutral-50;
  outline: none;
  font-weight: 700;
  &::placeholder {
    color: $color-neutral-500;
    font-weight: 400;
  }
}

.lg {
  input {
    @include body-large(false);
  }
  button {
    width: 35px;
    height: 35px;
  }
}
.md {
  input {
    @include body-medium(false);
  }
  button {
    width: 35px;
    height: 35px;
  }
}
.sm {
  input {
    @include body-small(false);
  }
  button {
    width: 24px;
    height: 24px;
  }
  svg {
    width: 12px;
    height: 12px;
  }
}

button.submit {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: $white;
  border: 2px solid $color-base-300;
  border-radius: 50%;
}

.question-mark {
  position: absolute;
  top: 0;
  right: -6px;
  display: flex;
}
