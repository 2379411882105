@use '@edwin-edu/ui/vars' as *;

.button {
  width: $space-6x;
  height: auto;
}

.modal {
  margin: $space-10x;
  max-width: 1200px;
}
