@use '~@edwin-edu/ui/vars' as *;

.welcome {
  font-size: 40px;
  font-weight: 400;
  color: $color-neutral-400;
  display: inline-block;
  width: 100%;
  img {
    display: inline;
  }
  
}

.blue {
  color: $color-base-300;
}

.logo {
  vertical-align: baseline;
  margin-bottom: -3px;
}
