@use '~@edwin-edu/ui/vars' as *;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 100%;
  width:  100%;
}
.head1 {
  margin-bottom: $space-8x;
}
.head2 {
  margin: 0 0 $space-8x;
  color: $color-base-300;
}
.footer {
  color: $color-neutral-500;
}
.buttonGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: $space-4x;
  margin: $space-4x 0;
}
.buttonGroup.vertical {
  flex-direction: column;
  margin-bottom: 0;

  & button {
    margin: 0;
  }
}

// TODO: utility class?
.error {
  color: $color-info-destructive;
  margin-top: $space-2x;
}
.message {
  font-weight: 400;
}
.districtName {
  text-transform: uppercase;
  color: $color-base-300;
  font-weight: 700;
}
.action {
  margin: $space-4x 0 $space-2x;
}
.unstyledList {
  position: relative;
  list-style: none;
  padding: 0;

  & li {
    display: inline-block;
    margin: 0;
  }
}
.gradeButton {
  margin: $space-1x;
}
.schoolLabel {
  margin-bottom: $space-1x;
}
.confirmSchool {
  text-transform: uppercase;
  color: $color-base-300;
  display: flex;
  flex-direction: row;
}
.email {
  color: $color-base-300;
  margin-bottom: $space-6x;
}
// styles from button.module.scss in home/ui. TODO: export styles or mixin?
.editBtn {
  width: 27px;
  height: auto;
}
// TODO: add flex to Button component?
.flexBtn {
  display: flex;
  padding: 8px;
  margin: -3px 0 0 10px;
}
.staticGradeContainer {
  position: relative;
  padding: 0 32px 0 0;
}
.staticGradeBtn {
    // styles from button.module.scss in home/ui. TODO: export styles or mixin?
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: $space-2x $space-6x;
    margin: $space-1x !important;
    border-radius: 40px;

    background: $color-neutral-500;
    color: $white;
}
.editGradeBtn {
  position: absolute;
  top: 20px;
  right: -12px;
}
.skipBtn {
  margin: $space-3x 0;
}
.underAccount {
  margin: 0 0 $space-2x;
}
.finalSkip {
  display: inline-block;
  margin-top: $space-4x;
}
.form {
  width: 100%;
  display: flex;
  flex-direction: column;
}