@use '~@edwin-edu/ui/vars' as *;

.outerContainer {
  position: relative;
}

.input {
  position: relative;
  left: 0;
  top: 3px;
  vertical-align: top;
  width: 30px;
  height: 17px;
  cursor: pointer;
  border-radius: 34px;
  background: transparent;
  caret-color: transparent;
  color: transparent;
  z-index: 2;
  border: none;
}


.input:focus-visible { outline: 2px solid $color-base-300; }
/* disable focus-styles for non-keyboard events */
.input:focus:not(:focus-visible) {
  outline: none;
}

.track {
  position: absolute;
  left: 0;
  top: 3px;
  width: 30px;
  height: 17px;
  border-radius: 34px;
  transition: 0.4s;
  border: 2px solid $color-neutral-300;
}

.activeTrackWhite {
  border: 2px solid $white;
  background: $white;
}

.activeTrack {
  border: 2px solid $color-base-300;
  background: $color-base-300;
}

.grayTrack {
  border-color: $color-neutral-300;

  &:after {
    background-color: $color-neutral-300;
  }
}

.thumb {
  position: absolute;
  content: '';
  height: 11px;
  width: 11px;
  top: 1px;
  left: 1px;
  transition: 0.4s;
  border-radius: 50%;
  background: $color-neutral-300;
}

.activeThumbWhite {
  background: $color-neutral-400;
  left: 14px;
}

.activeThumb {
  background: $white;
  left: 14px;
}

// @mixin defaultSwitchStyle { // TODO: CheckboxSwitch
//   top: 5px;
//   left: -28px;
//   position: relative;
//   cursor: pointer;

//   &:after {
//     position: absolute;
//     content: '';
//     height: 11px;
//     width: 11px;
//     top: 1px;
//     left: 2px;
//     bottom: 1.5px;
//     transition: 0.4s;
//     border-radius: 50%;
//     background: $color-neutral-300;
//   }
//  }
// .checkboxContainer {
//   input {
//     position: absolute;
//     opacity: 0;
//   }

//   span {
//     @include defaultSwitchStyle();
//     border-color: $color-neutral-300;
//     font-size: 0px;
//     &::after {
//       transform: none;
//       background-color: $color-neutral-300;
//     }
//   }

//   input:checked ~ span {
//     @include defaultSwitchStyle();
//     border-color: $black;
//     &::after {
//       transform: translateX(11px);
//       background-color: $black;
//     }
//   }
//  }
// .whiteCheckbox {
//   input:checked ~ span {
//     border-color: $white;
//     &::after {
//       background-color: $white;
//     }
//   }
// }
