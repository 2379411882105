@use '~@edwin-edu/ui/vars' as *;

.filters {
  margin: $space-1x 0 $space-3x;

  button {
    text-transform: capitalize;
  }
}

.rightAligned {
  position: relative;
  margin-left: auto;
  margin-top: -2px;
  @media (max-width: 990px) {
    margin-left: 133px;
  }
  @media (max-width: 890px) {
    margin-left: 22px;
  }
}

.keywordSearch {
  input {
    width: 200px;
    background: transparent;
    color: $color-base-300;
    font-weight: 700;
    line-height: 20px;
    @include body-large(false);

    &::placeholder {
      color: $color-neutral-300;
      @include body-medium(false);
    }
  }
}

.filterBy {
  color: #fff;
  z-index: 5;
}

.table {
  padding: $space-4x $space-10x 0;
  border-radius: $radius-1x;
  box-shadow: $shadow-neutral-0;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: $space-6x 0;
  color: $color-neutral-500;
  @include body-tag(false);
}

.empty {
  height: 400px;

  svg {
    margin-bottom: $space-4x;
  }

  ul {
    padding-left: $space-5x;
  }
}

.modal {
  width: 480px - 2 * $space-9x;

  p {
    color: $color-base-300;
  }
}

.close-button-success {
  margin-top: 24px;
}

.deactivationDropDown {
  margin-top: 24px;
  margin-bottom: 15px;
  box-shadow: 0px 8px 16px rgba(74, 74, 74, 0.25);
  button {
    width: 300px;
    justify-content: left;
    svg {
      margin-left: auto;
    }
  }
}

.deactivationOtherReason {
  margin-top: 16px;
}

.warningDifferentSchool {
  width: 300px;
  margin-top: 20px;
  span {
    color: $color-info-warning;
  }
}

.modalDesc {
  text-align: center;
  color: $color-base-300;
}

.changeRolesDesc {
  color: $color-base-300;
  font-size: 16px;
  letter-spacing: -0.4px;
  padding-top: $space-4x;
  padding-bottom: $space-4x;
  text-align: center;
}

.changeRolesConfirm {
  color: $color-base-300;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.3px;
  text-align: center;
  width: 260px;
  padding-bottom: $space-1x;
}

.changeRolesSuccess {
  @extend .changeRolesDesc;
  padding-top: $space-6x;
  padding-bottom: $space-0;
}

.changeRolesError {
  display: inherit;
  padding-top: $space-6x;
  padding-bottom: $space-0;
}

.actionButtonsContainer {
  margin-top: $space-6x;
  margin-bottom: $space-1x;
  gap: 10px;
}

.actionButton {
  margin-left: $space-2x;
  margin-right: $space-2x;
}

.spinnerContainer {
  margin-top: $space-6x;
  margin-bottom: $space-2x;
}

.searchPopover {
  position: absolute;
  background-color: white;
  border: none;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  overflow: visible;
  pointer-events: none;
  user-select: none;
  z-index: 500;
  width: 280px;
  min-height: 56px;
  top: 50px;
  right: -23px;
  padding: 15px;

  :before {
    bottom: 100%;
    left: 87%;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border: 14px solid rgba(255, 255, 255, 0);
    border-bottom-color: white;
    margin-left: -13px;
  }
}

.popoverText {
  font-size: 18px;
  text-align: left;
  color: $color-neutral-400;
  margin: $space-0;

  strong {
    font-weight: 600;
    color: $color-base-300;
  }
  em {
    font-weight: 600;
    font-size: 16px;
    font-style: italic;
    color: $color-base-300;
  }
}

.otherReasonTextarea {
  font-family: $font-families-source-sans-pro;
  padding: $space-2x;
  height: 86px;
  border-radius: $radius-1x;
  border-color: $grey-200;
  @include body-small(false);
}

.infoPopover {
  position: relative;
  overflow: visible;
  cursor: pointer;
  margin-top: -26px;
  margin-left: -16px;

  &:hover .popOver {
    opacity: 100;
  }
}
.infoIcon {
  width: 22px;
  height: 22px;
  & g,
  & path {
    stroke: $black;
  }
}
.popOver {
  opacity: 0;
  transition: opacity 0.15s ease-in;
  position: absolute;
  // position the popOverContent's top left corner in the middle of the icon
  top: 50%;
  left: 50%;
  width: 400px; // this could flex better...
  display: flex;
  pointer-events: none;
}
.popOverContent {
  display: inline-block;
  background: $color-neutral-0;
  box-shadow: $shadow-neutral-0;
  border-radius: $radius-1x;
  padding: $space-2x;
  z-index: 1;

  & h3,
  & ul,
  & li {
    font-size: $font-size-1;
    line-height: 1.5;
    color: $color-base-300;
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;
  }
}

.headerContainer {
  position: relative;
}

// copied over from @edwin-edu/ui/client/Table
.small {
  @include body-small(false);
}
.tr {
  height: $space-10x;
}
.grow {
  flex: 1 0 auto;
}
.shrink {
  flex: 0 1 auto;
}
.left {
  justify-content: start;
}
.center {
  justify-content: center;
}
.right {
  justify-content: end;
}
