@use '../../vars' as *;

// TODO: textAligns mixin in input & text?
$text-aligns: 'start', 'center', 'end';

.input {
  width: 100%;
  min-width: 0;
  position: relative;
  border: 1px solid $color-neutral-200;
  // border-radius: $space-1x;
  padding: $space-1x;
  padding-inline-start: $space-2x;
  padding-inline-end: $space-2x;
  transition-property: color, border-color, outline;
  transition-duration: 200ms;
  outline: 1px solid transparent;
  font-family: inherit;

  @each $text-align in $text-aligns {
    &.text-align-#{$text-align} {
      text-align: #{$text-align};
    }
  }
  &:focus {
    outline: 1px solid $color-base-200;
  }

  @include body-medium(false);
}

.default {
  color: $color-neutral-500;
  ::placeholder {
    color: $color-neutral-300;
  }
}

.filled {
  background-color: #cce4f5; // $color-base-300 at 20% opacity over white. no var for this
  border-color: #cce4f5;
  ::placeholder {
    color: $color-neutral-500;
  }
}

.error {
  border-color: $color-info-destructive;
}

.inputLabel {
  color: $color-base-300;
  display: inline-block;
  margin-bottom: $space-2x;
  cursor: pointer;

  @include body-tag(false);
}

.errorLabel {
  color: $color-info-destructive;
}

.inputHelper {
  color: $color-neutral-400;
  margin-top: $space-1x;

  @include body-tag(false);
}
