@use '~@edwin-edu/ui/vars' as *;

.studentProgressHeading {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: $black;
}
.container {
  background: $color-neutral-0;
  color: $color-neutral-500;
  min-height: 650px;
  min-width: 900px;
  box-shadow: 0px 8px 16px 0px rgba(1, 122, 204, 0.25);
  margin: 0 auto;
  padding: 48px 100px 0;
}
.classCodeContainer {
  flex: 1;
  min-width: 370px;
}
.descriptionContainer {
  display: inline-block;
  position: absolute;
}
.progressDescription {
  display: -webkit-inline-box;
}
.tutorialBottomText a {
  color: $color-neutral-500;
  text-decoration: underline;
}
.linkContainer {
  background: $color-neutral-50;
  height: 50px;
}
