@use '~@edwin-edu/ui/vars' as *;

.sectionGroup {
  min-width: 1000px;
  min-height: 480px;
}

.sectionGroupCenter {
  max-width: 1300px;
  width: 1300px;
  margin: 0 auto;
  padding: 40px 100px;

  @media (max-width: 1250px) {
    padding: 40px 40px;
  }
  @media (max-width: 1088px) {
    padding: 40px 20px;
    gap: 5px;
  }
}
.white {
  background: rgba(255, 255, 255, 0.8);
}
.section {
  flex: 1 0 475px;
  @media (max-width: 1088px) {
    max-width: 480px;
    padding: 0 20px 0 0;
  }
}

.description {
  height: 65px;
}

.sectionContent {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 200px;
  padding-top: $space-5x;
  padding-bottom: 50px;
}

.mathToolsContent {
  @extend .sectionContent;
  gap: 10px;
  align-items: flex-end;
  margin-top: 10px;
  margin-bottom: -10px;
  margin-left: -15px;

  @media (max-width: 1088px) {
    max-width: 470px;
    gap: 1px;
  }
}

.mathToolsContent.skeleton {
  gap: $space-6x;
  margin-left: 0;
}
