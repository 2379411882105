@use '~@edwin-edu/ui/vars' as *;

.emptyCollection {
  opacity: 0.6;
}

.select {
  top: -8px;
  right: -8px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  background: white;
  border-radius: 50%;
  box-shadow:
    0 0 0 3px rgb(20, 208, 255),
    0 0 3px 0 rgba(42, 42, 42, 0.2);
  z-index: 3;
}

.scrollContainer {
  flex: 1 1 auto;
  min-height: 0;
  padding-bottom: 80px;
  overflow-y: auto;
  margin-top: $space-13x;
  @include scrollbar();
}

.containerOnSelect {
  position: relative;
  top: -$space-2x;
  right: -$space-2x;
  left: 0;
  bottom: 0;
  min-height: calc(100% - $space-10x);
  padding-top: $space-2x;
  padding-right: $space-2x;
  overflow-x: hidden;
}

.selected::after {
  content: '';
  width: 5px;
  height: 10px;
  border-style: solid;
  border-color: #017acc;
  border-image: initial;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  margin-top: -3px;
  right: 0;
}

.selectedCollection,
.collectionCardContainer {
  position: relative;
  margin: 5px;
}

.selectedCollection {
  box-shadow: rgba(20, 208, 255, 0.25) 0 0 0 8px;
  margin: 5px;
  position: relative;
}

.selectedButtonStyles,
.unSelectedButtonStyles {
  font-weight: 600;
  color: #2a2a2a;
  line-height: $space-6x;
  font-size: $space-4x;
}

.unSelectedButtonStyles {
  opacity: 0.6;

  &:hover {
    cursor: not-allowed;
  }
}

.deleteWarningMessage {
  margin-top: $space-5x;
}

.cancelButton {
  color: rgb(1, 122, 204);
  font-size: 14px;
  font-weight: 400;

  &:hover {
    color: #004d80;
  }
}

.separator {
  display: inline-block;
  border-right: 1px solid #2a2a2a;
  height: $space-5x;
  margin: 0 $space-4x;
}

.separatorSpan {
  padding-right: 10px;
  padding-left: 1px;
  font-size: 20px;
  font-weight: 200;
}

.unselectedBorder {
  border: 3px solid rgba(42, 174, 255, 0.4);
}

.selectedBorder {
  border-color: #2aaeff;
}

.select {
  top: -8px;
  right: -8px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  background: white;
  border-radius: 50%;
  box-shadow:
    0 0 0 3px rgb(20, 208, 255),
    0 0 3px 0 rgba(42, 42, 42, 0.2);
  z-index: 2;
}

.selected::after {
  content: '';
  width: 5px;
  height: 10px;
  border-style: solid;
  border-color: #017acc;
  border-image: initial;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  margin-top: -3px;
  right: 0;
}

.selectedCollection,
.collectionCardContainer {
  position: relative;
  margin: 5px;
}

.selectedCollection {
  box-shadow: rgba(20, 208, 255, 0.25) 0px 0px 0px 8px;
  margin: 5px;
  position: relative;
}

.tooltip {
  z-index: 4;
}

.actionsRow {
  margin-bottom: $space-3x;
  height: $space-9x;
  position: absolute;
  right: 0;
  top: $space-2x;
  span {
    display: flex;
  }
}

.emptyState {
  @include gradientBorderStyles(4, 1);
}

.itemsCount {
  font-weight: 700;
  line-height: $space-6x;
  white-space: nowrap;
}

.copyToYourCollectionsButton {
  margin: auto $space-1x
}
