@use '~@edwin-edu/ui/vars' as *;

.listContainer {
  flex: 0 0 380px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: linear-gradient(128.8deg, #dcf2ff 25.7%, #eaf8ff 81.5%);
  opacity: 0.9;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
  border-radius: 6px;
  overflow-y: auto;
  padding: $space-2x $space-6x $space-6x;
  @include scrollbar;
}

.marketingSubjectHeader {
  color: $color-neutral-500;
  font-weight: 700;
  margin: 0.5rem 0 1rem;

  &:first-child {
    margin-top: 0;
  }
}

.filterIcon {
  width: 19px;
  height: auto;
  margin-right: 8px;
  path {
    stroke: $color-neutral-500;
  }
  circle {
    fill: $color-neutral-500;
  }
}

.filtersTitle {
  font-weight: 700;
  font-size: 16px;
  color: $color-neutral-500;
  user-select: none;
}

.resetButton {
  font-weight: 600;
  font-size: 12px;
  color: $color-neutral-500;
  position: absolute;
  cursor: pointer;
  top: 74px;
  right: 20px;
  z-index: 4;
}
