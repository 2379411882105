@use '~@edwin-edu/ui/vars' as *;

.coverDashboardModal {
  width: 480px;
  background-color: $white;
  box-shadow: $shadow-neutral-2;
  border-radius: $space-2x;

  &.reader {
    border-radius: 0 0 $space-2x $space-2x;
  }

  hr {
    margin: 0;
  }

  .coverDashboardList {
    height: 144px;
    overflow-y: auto;
    @include scrollbar();
  }
}

.coverDashboardModalButton {
  &.open {
    @include svgHoverStyles($color-base-300, $color-base-300, $color-base-300);
  }

  &.reader {
    height: 50px;
    width: 55px;
    padding: 0 $space-1x;

    &.open {
      background-color: $white;
    }
  }

  &.gray {
    path {
      stroke: $color-neutral-200 !important;
    }
  }

}

.coverDashboardItem {
  padding: $space-2x;

  .coverDashboardName {
    flex: 0 0 336px;
    overflow: hidden;
    font-size: $font-size-1;
    color: $color-neutral-400;
  }

  label.coverDashboardCheckbox {
    input[type='checkbox'] {
      margin-top: 7px;

      &:checked {
        & ~ svg {
          top: 4px;
        }

        & ~ span {
          font-weight: 400;
          color: $color-base-300;
        }
      }
    }

    span {
      @include body-small(false);
    }
  }
}

.text {
  color: $color-neutral-300;
}

.noCurriculum {
  color: $color-neutral-500;
}
