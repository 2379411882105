@use '~@edwin-edu/ui/vars' as *;

.modal {
  background: none;
  padding: 0;
}
.modalContent {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 680px;
  height: 500px;
  padding: $space-6x $space-10x;
  border-radius: 8px;
  border: 1px solid $color-neutral-500;
  background: radial-gradient(
      circle at 10% 20%,
      rgba(200, 235, 255, 0.1),
      white,
      rgba(200, 235, 255, 0.1)
    )
    rgb(200, 235, 255);
  box-shadow: 0px 8px 16px 0px rgba(177, 227, 255, 0.25);
  font-size: inherit;
  color: $color-neutral-500;
}
.joinContainer {
  min-width: 0;
  margin-top: $space-3x;
  display: inline-grid;
}
.joinLink {
  font-size: 24px;
  font-style: normal;
  line-height: 36px; /* 150% */
  text-decoration-line: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blueJoinLink {
  @extend .joinLink;
  color: $color-base-300;
}
.copyContainer {
  position: relative;
  width: 95px;
}
.copyButton {
  height: $space-6x;
  min-width: 85px;
  padding: $space-1x $space-6x;
  margin-left: $space-2x;
  font-size: 12px;
}
.copiedMsg {
  position: absolute;
  top: -33px;
}
.joinedIcon {
  width: 26px;
  margin: -2px 3px 0 0;
}
.classCodeText {
  color: $color-base-300;
  user-select: none;
  margin-top: 0;
  margin-bottom: $space-1x;
}
.classCode {
  font-size: 24px;
  line-height: 50px;
  margin: -5px 0 $space-2x;
  white-space: nowrap;
}
.classCodeChar {
  width: 32px;
  margin-right: $space-2x;
  padding: 0 $space-2x $space-1x;
  border-bottom: 0.612px solid #979797;
  text-align: center;
}
.descText {
  font-weight: 400;
  user-select: none;
  max-width: 550px;
}
.infoText {
  max-width: 500px;
}
.infoTextBold {
  font-weight: 700;
}
.hr {
  border-bottom: 1px solid black;
}
.className {
  margin-top: $space-4x;
  max-width: 580px;
  @include lineClamp(2);
}
.closeButton {
  position: absolute;
  top: 15px;
  right: 25px;
}
