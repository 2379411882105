@use '../../vars' as *;

.base {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 440px;
  background: $color-neutral-0 $bg-gradient;
  border-radius: $radius-2x;
  box-shadow: $shadow-base-3;
  padding: $space-8x;
  text-align: center;

  &.error {
    border: 1px solid $color-info-destructive;
  }
}
