@use '~@edwin-edu/ui/vars' as *;

.container{
  position: absolute;
  display: flex;
  height: 48px;
  right: 22px;
  top: 58px;
  background: #e0f4ff;
  border: 1px solid $color-base-200;
  box-shadow: 0px 2px 4px rgba(1, 122, 204, 0.25);
  border-radius: 4px;

  z-index: 10;

  button {
    padding: 8px 16px;
  }

  > :hover {
    transition: background 300ms ease-in;
    background: rgba(255, 255, 255, 0.4);
  }
}

.openButton{
  display: flex;
  align-items: center;
  gap: 12px;
  width: max-content;
  padding: 8px 8px 8px 4px;
  cursor: pointer;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background: $color-base-300;
    box-shadow: 0px 2px 4px rgba(1, 122, 204, 0.25);
    border-radius: 4px;
    flex: none;
    flex-grow: 0;
  }

  > span {
    flex-shrink: 0;
    user-select: none;
  }
}
