@use '../../vars' as *;

.base {
  width: fit-content;
  padding: $space-2x $space-1x;
  border-width: 1px;
  border-style: solid;
  border-radius: $radius-1x;
  box-shadow: $shadow-neutral-0;
}

.info {
  background-color: $blue-50;
  border-color: $color-base-200;
}
.success {
  background-color: $green-50;
  border-color: $color-info-success;
}
.warning {
  background-color: $orange-50;
  border-color: $color-info-warning;
}
.danger {
  background-color: $red-50;
  border-color: $color-info-destructive;
}

.icon {
  width: 32px;
  height: 32px;
  border-radius: $radius-1x;
}
.icon-info {
  background: $color-base-300;
}
.icon-success {
  background: $color-info-success;
}
.icon-warning {
  background: $color-info-warning;
}
.icon-danger {
  background: $color-info-destructive;
}
