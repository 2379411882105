@use '@edwin-edu/ui/vars' as *;

.actionButton {
  display: flex;
  position: sticky;
  justify-content: center;
  align-items: center;
  flex: 1 1 100%;
  height: 50px;
  width: 60px;
  @include svgHoverStyles($color-neutral-500, $color-base-400, $color-base-400);
  
  & svg {
    pointer-events: none;
  }

}

.teams {
  width: 40px;
}

.active {
  background: #FFFFFF;
  @include svgHoverStyles($color-base-300, $color-base-300, $color-base-300);
}

.addToCollectionsPopoverContainer {
  top: 50px !important;
}
.addToCollectionsPopoverContent {
  padding: 0;
  border-radius: 0 0 $radius-1x $radius-1x;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
}

.coverDashboardContent {
  border-radius: 0 0 $radius-1x $radius-1x;
}

.playButton {
  margin-top: 5px;
  flex-shrink: 1;
}
