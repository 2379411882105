@use '~@edwin-edu/ui/vars' as *;

.errorContainer {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.leftContainer {
  padding: 10px 40px 30px;
  align-items: center;
  svg {
    width: 145px;
  }
}
.rightContainer {
  color: white;
  margin: 40px 50px;
  color: $color-neutral-500;
  min-width: 600px;
  font-family: "Source Sans Pro", sans-serif;
}
.verticalDivider {
  border-left: 1px solid rgba(42, 42, 42, 0.5);
  height: 300px;
}
.errorType {
  color: $color-base-300;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.3px;
}
.errorMessage {
  color: $color-neutral-500;
  font-size: 28px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.3px;
  margin: $space-4x 0 $space-4x;
}
.errorActionMessage {
  margin-bottom: $space-6x;
  color: $color-neutral-500;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.3px;
  margin-bottom: $space-5x
}
.readerContainer {
  display: flex;
  height: calc(100vh - 50px);
  width: 100vw;
  justify-content: center;
}
.readerErrorContent {
  font-family: "Source Sans Pro";
  color: $color-neutral-500;
  width: 468px;
  margin: auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  transform: translateY(-30px);
  font-family: "Source Sans Pro", sans-serif;
}
// TODO: Modify UI composition to utilize passed in classes and styles as the default
.link {
  text-decoration: underline !important;
  color: $color-neutral-500 !important;
  font-weight: 700 !important;
}
