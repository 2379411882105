@use '~@edwin-edu/ui/vars' as *;

.modalContainer {
  position: relative;
  padding: 5px;
}

.modal {
  width: 670px;
  min-height: 341.69px;
  min-width: 200px;
  min-height: auto;
  padding: 20px;
  margin: auto;
  flex: 0 1 auto;
  scrollbar-width: thin;
  transform: scale(1) translateX(0);
}

.sectionTitle {
  margin-bottom: 10px;
  color: $color-base-300;
  font-weight: 300;
  font-size: 28px;
  letter-spacing: -1.05px;
}

.addItemsDescription {
  margin-top: 25px;
}

.addLearningObjectsDescription {
  display: inline-flex;
  vertical-align: middle;
}

.addCollectionIcon {
  margin-left: 4px;
  margin-right: 2px;
  width: 25px;
  height: auto;
}

.addItemsButton {
  margin: 10px auto;
  font-size: 14px;
}

.fileFormButtonContainer {
  display: 'flex';
  flex-direction: 'row';
  gap: 8;
  justify-content: 'space-between';
}

.closeButton {
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
  color: $color-base-300;
  opacity: 0.6;
  font-size: 14px;
}

.addItemsModalButtonsContainer {
  justify-content: 'space-between';
}

.italicText {
  font-style: italic;
  text-align: center;
  max-width: 200px;
  font-size: 12px;
  line-height: 1.25;
}

.inputContainer {
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  margin-bottom: 10px;
}

.inputLabel {
  flex: 1 0 auto;
  text-align: right;
  margin-right: 10px;
  font-style: italic;
  color: $color-neutral-400;
  font-weight: 400;
  font-size: 16px;
}

.input {
  color: $color-base-300;
  width: 300px;
  height: $space-8x;
  font-style: italic;
  text-transform: lowercase;
}

.inputLong {
  border: 1px solid rgb(188, 188, 188);
  color: $color-base-300;
  height: $space-8x;
  font-style: italic;
}

.textarea {
  border: 1px solid rgb(188, 188, 188);
  color: $color-base-300;
  height: 72px;
  resize: none;
  font-style: italic;
}

.teacherOnly {
  user-select: none;
  font-size: 12px;
  color: $color-neutral-400;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

.teacherOnlyOption {
  margin-top: -6px;
}

.requiredField {
  position: absolute;
  left: 29px;
  bottom: 5px;
  color: $color-neutral-500;
  font-size: 10px;
  letter-spacing: -0.25px;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: $space-5x 0 $space-7x;
}

.buttonsContainerFile {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60px;
  align-items: flex-end;
  margin: 15px 0px 10px;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
}

.fileUploadIcon {
  margin-left: 10px;
  // center with flex
  display: flex;
  justify-content: center;
  align-items: center;
}

.fileFormContainer {
  min-width: 520px;
  height: 100%;
  border-color: rgb(234, 234, 234);
  background-color: rgb(250, 250, 250);
  border-style: dotted;
  border: 1 dotted rgb(234, 234, 234);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fileInput {
  display: none;
}

.fileDescription1 {
  color: $color-base-300;
  font-size: 16px;

  >span {
    font-weight: bold;
    text-decoration: underline;
  }

  & {
    margin-bottom: -4px;
  }
}

.fileDescription2 {
  color: #888888;
  font-size: 10px;
  text-align: center;
  font-weight: bold;

  margin-top: -1px;
  margin-bottom: -5px;
}

.fileDescription3 {
  color: #888888;
  font-size: 10px;
  text-align: center;
  font-weight: bold;

  margin-top: -4px;
}

.dropZoneUploadContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.dropZoneUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // justify-content: flex-end;
  padding-right: 20px;
  border-width: 2.3px;
  border-radius: 4px;
  color: #017acc;
  transition: border .24s ease-in-out;
  margin: 0 0 5px 0;
  cursor: pointer;
}

.dropContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
  border-width: 2.3px;
  border-radius: 4px;
  color: $color-base-300;
  transition: border 0.24s ease-in-out;
  margin: 0 0 5px 0;
  cursor: pointer;
}

.browseFilesButtonContainer {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 25px;
}

.browseFilesButton {
  font-weight: 700;
}

.browseFilesFilename {
  margin-left: 20px;
  color: $color-base-300;
  font-size: 18px;
}

.browseButton {
  margin: 6px auto 0px 0px;
}

.messageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.instructions {
  display: inline;
  text-align: center;
  color: $color-base-300;
  font-weight: 400;
  margin-top: 10px;

  span {
    font-weight: 700;
    font-size: 16px;
    text-decoration: underline;
  }
}

.h6 {
  font-size: 10px;
  color: #888;
  text-align: center;
}

.errorContainer {
  display: flex;
  align-items: center;
  user-select: none;
}

.error {
  color: $color-info-destructive;
  margin-left: 8px;
  font-size: 11px;
}

.selectedFile {
  display: flex;
  align-items: center;
}

.h4 {
  margin-left: 25px;
  color: $color-base-300;
  font-size: 16px;
  font-weight: bold;
  display: flex;
}

.thumb {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
}

.thumbsContainer {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
}

.thumbInner {
  display: flex;
  min-width: 0;
  overflow: hidden;
}

.infoContainer {
  display: flex;
  align-content: center;
  overflow: hidden;
}

.thumbnailContainer {
  flex: 1 1 auto;
  background-repeat: no-repeat;
  background-size: contain;
  left: 20%;
  top: 20%;
  background-position: center;
}

.fileUploadIcon {
  margin-left: 10px;
  // center with flex
  display: flex;
  justify-content: center;
  align-items: center;
}


.previewContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  /* Add some space between the image and file info */
}

.previewImage {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
}

.previewPlaceholder {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  background-color: #fff;
  font-size: 12px;
  text-align: center;
}

.infoContainer {
  display: flex;
  flex-direction: column;
}

.h4 {
  margin-left: 0;
}

.uploadMessage {
  white-space: nowrap;
  font-style: italic;
}
