@use '@edwin-edu/ui/vars' as *;

.container{
  display: flex;
  width: 110px;
  margin-right: -12px;
  user-select: none;
  user-drag: none;
}

.arrow {
  pointer-events: auto;
  filter: grayscale(100%);

  &:hover {
    filter: grayscale(0%);
  }
}

.arrowDisabled{
  pointer-events: none;

  filter: grayscale(100%) brightness(200%);
}

.leftSpacing {
  margin-right: 5px;
}
