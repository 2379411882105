.iconWrapper {
  background: rgba(255, 255, 255, 0.9);
  padding: 5px 10px 5px 5px;
  border-radius: 40px;
  color: #2a2a2a;
}

.icon {
  display: inline-block;
  margin-top: -3px;

  & circle {
    fill: #14D0FF !important;
  }

  & path {
    stroke: #FFF !important;
  }
}
