@use '~@edwin-edu/ui/vars' as *;

.tab {
  display: flex;
  flex-direction: column;
  align-items: center;

  font-weight: 400;

  &::after {
    content: '';
    display: block;
    width: 90%;
    min-width: 62px;
    height: 6px;
    background-color: $color-neutral-200;
    border: 1px solid $color-neutral-300;
    border-radius: $radius-1x;
  }
}
.activeTab {
  font-weight: 700;

  &::after {
    border-color: $color-base-300;
    background-color: $color-base-200;
  }
}
