@use '../../vars' as *;

$resizes: 'auto', 'both', 'horizontal', 'vertical', 'none';

@layer edwin {
  .textarea {
    font-family: inherit;

    @each $resize in $resizes {
      &.resize-#{$resize} {
        resize: #{$resize};
      }
    }
  }
}