@use '../../vars' as *;

@layer edwin {
  .list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .item {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .scrollable {
    overflow-y: auto;
    overflow-x: hidden;
  }
}
