@use '~@edwin-edu/ui/vars' as *;

.tableContainer {
  position: relative;
  flex: 1;
  background-color: $color-neutral-0;
  border-radius: $radius-1x;
  box-shadow: $shadow-neutral-0;
  padding: $space-7x $space-10x;
  min-height: 300px;
}

.studentName {
  font-weight: bold;
}

.td {
  border-bottom: none;
}

.tr {
  transition: background 0.2s ease-out, border 0.2s ease-out;
  background: transparent;
  border: 1px solid transparent;
  // copied over from @edwin-edu/ui/client/Table
  height: $space-10x;

  &:hover {
    background: $color-neutral-50;
    border-top: 1px solid $color-neutral-200;
    border-bottom: 1px solid $color-neutral-200;
  }
}

.link {
  display: inline;
}

.loading {
  height: 80%;
}

.scroll {
  overflow-y: auto;
  @include scrollbar;
}

.pieContainer {
  position: absolute;
  top: 20px;
  right: -40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.avgTime {
  position: relative;
  display: flex;
  margin-top: $space-3x;
  justify-content: center;
  align-items: center;
  min-width: 90px;
  height: 28px;
  background: $white;
  border-radius: 28px;
  color: $color-base-300;
  filter: drop-shadow(3px 6px 13px rgba(0, 0, 0, 0.22));
  font-size: $font-size-0;

  & span:first-child {
    margin-right: 5px;
    font-size: $font-size-2;
  }
}

// copied over from @edwin-edu/ui/client/Table
.small {
  @include body-small(false);
}

.grow {
  flex: 1 0 auto;
}

.shrink {
  flex: 0 1 auto;
}

.left {
  justify-content: start;
}

.center {
  justify-content: center;
}

.right {
  justify-content: end;
}
