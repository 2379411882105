@use '~@edwin-edu/ui/vars' as *;

.ribbon {
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  color: $color-neutral-0;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  z-index: 5;

  div {
    position: absolute;
    top: 3px;
    left: -11px;
    width: 120px;
    padding-right: 25px;
    background: $color-base-300;
    transform: rotate(-24.4deg);
  }
}
