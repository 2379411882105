@use '@edwin-edu/ui/vars' as *;

.notificationItem {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 318px;
  margin-bottom: 5px;
  padding: 15px 25px 20px;
  background: rgba(0, 0, 0, 1);
  border-radius: 3px;
  box-shadow: 0 0 10px 0 rgba(20, 208, 255, 0.15);
  color: $white;
  font-size: 14px;
  transition: color 0.2s;
  color: $white;
}
.unread {
  color: $color-base-300;
}
.unreadNotificationIcon {
  position: absolute;
  right: 22px;
  width: 22px;
  height: 22px;
  pointer-events: none;
}
.notifDate {
  font-size: 12px;
  margin-bottom: 5px;
}
.notifTitle {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
}
.notifLink {
  align-self: flex-end;
  margin-top: 10px;
  color: $white;
  font-size: 12px;
  text-decoration: underline;
}
.white {
  color: $white !important;
}
